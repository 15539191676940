import { Box, Container, Switch, Tooltip, Typography } from '@mui/material';
import { getAdjustmentDefinition, getAdjustments } from '@services/api';
import { useApi, useLoader } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import AlertIcon from '@assets/icons/dashboard/alert-triangle-filled.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { IProject } from '@models/interfaces/entities/IProject';
import { Loader } from '@components/Loader';
import clsx from 'clsx';
import useStyles from './styles';

export enum AdjustmentOperation {
  Initial = 'initial',
  Manual = 'manual',
  Add = 'add',
  Subtract = 'subtract',
  Match = 'match',
  Fees = 'fees',
  Balance = 'balance',
}

export const OperationMap: Record<string, string> = {
  initial: 'Initial',
  manual: 'Manual',
  add: 'Add',
  subtract: 'Subtract',
  match: 'Match',
  fees: 'Fees',
  balance: 'Balance',
};

export interface IAdjustmentDetails {
  adjustmentDefinitionId: string;
  sourceSubAccountId?: string | null;
  sourceSubAccountDescription?: string | null;
  operation: string;
  sourceTotal: number;
  matchingBalance: number;
  sourceAdjustment: number;
  adjustments: IAdjustment[];
}

interface IProps {
  details: IAdjustmentDetails;
  adjustments: IAdjustment[];
  accounts: IAccount[];
  category: string;
  project: IProject;
  type: number;
}

export const AdjustmentDetails = ({
  details,
  accounts,
  adjustments,
  category,
  project,
  type,
}: IProps) => {
  const { classes } = useStyles();

  const [adjustmentDefinition, setAdjustmentDefinition] = useState<IAdjustmentDefinition>();
  const [detailsAdjustments, setDetailsAdjustments] = useState<IAdjustment[]>([]);
  const [balanceAdjustments, setBalanceAdjustments] = useState<IAdjustment[]>([]);

  const { request: getAdjustmentDefinitionRequest, loading: getAdjustmentDefinitionLoading } =
    useApi(getAdjustmentDefinition, null, {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setAdjustmentDefinition(data);
        }
      },
    });

  const { request: getAdjustmentsRequest, loading: getAdjustmentsLoading } = useApi(
    getAdjustments,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          const balanceAdjustments = data.items.filter(
            (x) =>
              x.operation === AdjustmentOperation.Initial ||
              x.operation === AdjustmentOperation.Manual,
          );
          setBalanceAdjustments(balanceAdjustments);

          const detailsAdjustments = data.items.filter(
            (x) => x.adjustmentDefinitionId === details.adjustmentDefinitionId,
          );
          setDetailsAdjustments(detailsAdjustments);
        }
      },
    },
  );

  const getBalanceLabel = useCallback((value: number) => {
    const formatted = `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Math.abs(value))}`;
    return value < 0 ? `(${formatted})` : formatted;
  }, []);

  const getPercentageLabel = useCallback((value: number) => {
    const formatted = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);

    return formatted;
  }, []);

  const getAccountTotalBalance = useCallback(
    (accountId: string, operations: string[], adjustments: IAdjustment[]) => {
      return adjustments
        .filter((x) => x.accountId === accountId && operations.includes(x.operation))
        .reduce((p, c) => p + c.amount, 0);
    },
    [],
  );

  const detailAdjustmentItems = useMemo(() => {
    let balanceOperations: string[] = [];
    if (
      details.operation === AdjustmentOperation.Add ||
      details.operation === AdjustmentOperation.Subtract ||
      details.operation === AdjustmentOperation.Match
    ) {
      balanceOperations = [AdjustmentOperation.Initial, AdjustmentOperation.Manual];
    } else if (details.operation === AdjustmentOperation.Fees) {
      balanceOperations = [
        AdjustmentOperation.Initial,
        AdjustmentOperation.Manual,
        AdjustmentOperation.Add,
        AdjustmentOperation.Subtract,
        AdjustmentOperation.Match,
      ];
    }

    const baselineAccountIds =
      details.operation === AdjustmentOperation.Match && adjustmentDefinition
        ? adjustmentDefinition.baseline.map((x) => x.id)
        : [];

    const baselineOnlyAccountIds =
      details.operation === AdjustmentOperation.Match && adjustmentDefinition
        ? baselineAccountIds.filter(
            (x) => adjustmentDefinition.targets.findIndex((a) => a.id === x) === -1,
          )
        : [];

    const targetAccountsData = detailsAdjustments.map((x) => {
      const account = accounts.find((a) => a.id === x.accountId);
      return {
        account,
        initialBalance:
          details.operation === AdjustmentOperation.Match
            ? getAccountTotalBalance(x.accountId, [AdjustmentOperation.Initial], balanceAdjustments)
            : 0,
        balance: getAccountTotalBalance(x.accountId, balanceOperations, balanceAdjustments),
        adjustment: x.amount,
        adjusted: true,
        isBaseline: baselineAccountIds.includes(x.accountId),
        hasCorrectType:
          account &&
          account.accountType.type === type &&
          !account.isBalancing &&
          account.summaryCode !== 1,
      };
    });

    const baselineAccountsData = baselineOnlyAccountIds.map((x) => {
      const account = accounts.find((a) => a.id === x);
      return {
        account,
        initialBalance: getAccountTotalBalance(
          x,
          [AdjustmentOperation.Initial],
          balanceAdjustments,
        ),
        balance: 0,
        adjustment: 0,
        adjusted: false,
        isBaseline: true,
        hasCorrectType:
          account &&
          account.accountType.type === type &&
          !account.isBalancing &&
          account.summaryCode !== 1,
      };
    });

    return [...targetAccountsData, ...baselineAccountsData];
  }, [
    detailsAdjustments,
    accounts,
    type,
    balanceAdjustments,
    details.operation,
    details.adjustmentDefinitionId,
    adjustmentDefinition,
  ]);

  const filteredAccounts = useMemo(
    () =>
      accounts.filter((x) => x.accountType.type === type && x.summaryCode !== 1 && !x.isBalancing),
    [accounts],
  );

  useEffect(() => {
    if (project.links[Actions.getAdjustmentDefinitions]?.href)
      getAdjustmentDefinitionRequest(
        `${project.links[Actions.getAdjustmentDefinitions].href}/${details.adjustmentDefinitionId}`,
      );
  }, [project.links[Actions.getAdjustmentDefinitions]?.href, details.adjustmentDefinitionId]);

  useEffect(() => {
    if (adjustmentDefinition) {
      const usedAccountIds = Array.from(
        new Set([
          ...adjustmentDefinition.targets.map((x) => x.id),
          ...adjustmentDefinition.baseline.map((x) => x.id),
        ]),
      );

      // detected account from another category
      if (usedAccountIds.some((x) => !filteredAccounts.find((a) => a.id === x))) {
        if (project.links[Actions.getAdjustments]?.href) {
          getAdjustmentsRequest(
            project.links[Actions.getAdjustments].href,
            [AdjustmentOperation.Initial, AdjustmentOperation.Manual, details.operation],
            usedAccountIds,
          );
        }
      } else {
        copyAdjustmentsFromProps();
      }
    }
  }, [filteredAccounts, adjustmentDefinition, project.links[Actions.getAdjustments]?.href]);

  const copyAdjustmentsFromProps = () => {
    setDetailsAdjustments(details.adjustments);
    setBalanceAdjustments(adjustments);
  };

  const detailAdjustmentsTotalAdjustment = useMemo(
    () => detailAdjustmentItems.reduce((p, c) => p + c.adjustment, 0),
    [detailAdjustmentItems],
  );

  const detailAdjustmentsTotalAccountsBalance = useMemo(
    () => detailAdjustmentItems.reduce((p, c) => p + c.balance, 0),
    [detailAdjustmentItems],
  );

  const isMatch = useMemo(
    () => details.operation === AdjustmentOperation.Match,
    [details.operation],
  );

  const titleClass = useMemo(() => (isMatch ? classes.colSpan5 : classes.colSpan4), [isMatch]);
  const adjustmentColumnPositionClass = useMemo(
    () => (isMatch ? classes.col6 : classes.col5),
    [isMatch],
  );
  const balanceColumnPositionClass = useMemo(
    () => (isMatch ? classes.col4 : classes.col3),
    [isMatch],
  );
  const spacerClass = useMemo(() => (isMatch ? classes.col6Spacer : classes.col5Spacer), [isMatch]);
  const gridClass = useMemo(() => (isMatch ? classes.col6Grid : classes.col5Grid), [isMatch]);

  const showLoader = useLoader(getAdjustmentDefinitionLoading, getAdjustmentsLoading);

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth='md'>
          {details.operation === AdjustmentOperation.Add ||
          details.operation === AdjustmentOperation.Subtract ||
          details.operation === AdjustmentOperation.Fees ||
          details.operation === AdjustmentOperation.Match ? (
            <Box className={clsx([classes.grid, gridClass])}>
              <Box className={clsx([classes.header, titleClass])}>{details.sourceSubAccountId}</Box>
              <Box className={clsx([classes.header, classes.textRight])}>
                {OperationMap[details.operation]}
              </Box>
              <Box className={spacerClass} />
              <Box className={classes.header}>Line Item Balance</Box>
              <Box className={clsx([classes.header, classes.textRight])}>
                {getBalanceLabel(details.sourceTotal)}
              </Box>
              <Box className={spacerClass} />
              <Box className={clsx([classes.header, 'alignItemsEnd'])}>
                {isMatch ? 'Match Balance To' : 'Apply Adjustment To'}
              </Box>
              {isMatch && (
                <>
                  <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                    Source Balance
                  </Box>
                  <Box className={clsx([classes.header, classes.textCenter, 'alignItemsEnd'])}>
                    Adjust
                  </Box>
                </>
              )}
              <Box
                className={clsx([
                  classes.header,
                  balanceColumnPositionClass,
                  classes.textRight,
                  'alignItemsEnd',
                ])}
              >
                {isMatch ? 'Manually ' : ''}Adjusted Balance
              </Box>
              <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                Portion of Total
              </Box>
              <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                Adjustment
              </Box>

              {detailAdjustmentItems.map((x) => (
                <>
                  <Box
                    className={clsx([
                      classes.pl16,
                      classes.col1,
                      (!x.hasCorrectType || (!x.isBaseline && isMatch)) && classes.red,
                    ])}
                  >
                    {x.isBaseline || !isMatch ? (
                      x.hasCorrectType ? (
                        x.account?.accountName || 'Unknown'
                      ) : (
                        <Tooltip
                          title={`This account is not of the correct type (in this case, ${category}). Adjustments defined within a particular account category should only target accounts of the corresponding category.`}
                        >
                          <Box className={clsx([classes.flex, classes.red])}>
                            <img src={AlertIcon} alt='alert' />
                            {x.account?.accountName || 'Unknown'}
                          </Box>
                        </Tooltip>
                      )
                    ) : (
                      <Tooltip
                        title={
                          <Box>
                            This account is not included in the baseline adjustment calculation. To
                            remedy this, either:
                            <ul>
                              <li>Add this account to the baseline adjustment calculation, or</li>
                              <li>Remove this account as an adjustment target.</li>
                            </ul>
                          </Box>
                        }
                      >
                        <Box className={clsx([classes.flex, classes.red])}>
                          <img src={AlertIcon} alt='alert' />
                          {x.account?.accountName || 'Unknown'}
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                  {isMatch && (
                    <>
                      <Box
                        className={clsx([
                          classes.textRight,
                          (!x.hasCorrectType || (!x.isBaseline && isMatch)) && classes.red,
                        ])}
                      >
                        {getBalanceLabel(x.isBaseline ? x.initialBalance : 0)}
                      </Box>
                      <Box className={clsx([classes.textCenter])}>
                        <Switch
                          inputProps={{ role: 'switch' }}
                          checked={x.adjusted}
                          size='small'
                          disabled
                        />
                      </Box>
                    </>
                  )}
                  {x.adjusted && (
                    <Box
                      className={clsx([
                        balanceColumnPositionClass,
                        classes.textRight,
                        (!x.hasCorrectType || (!x.isBaseline && isMatch)) && classes.red,
                      ])}
                    >
                      {getBalanceLabel(x.balance)}
                    </Box>
                  )}
                  {x.adjusted && (
                    <Box
                      className={clsx([
                        classes.textRight,
                        (!x.hasCorrectType || (!x.isBaseline && isMatch)) && classes.red,
                      ])}
                    >
                      {getPercentageLabel(
                        detailAdjustmentsTotalAccountsBalance
                          ? x.balance / detailAdjustmentsTotalAccountsBalance
                          : 1 / detailAdjustmentItems.length,
                      )}
                    </Box>
                  )}
                  {x.adjusted && (
                    <Box className={clsx([classes.green, classes.textRight])}>
                      {getBalanceLabel(x.adjustment)}
                    </Box>
                  )}
                </>
              ))}

              <Box className={clsx([classes.pl16, classes.header])}>Total</Box>
              <Box
                className={clsx([classes.header, balanceColumnPositionClass, classes.textRight])}
              >
                {getBalanceLabel(detailAdjustmentsTotalAccountsBalance)}
              </Box>
              <Box className={clsx([classes.header, classes.textRight])}>
                {getPercentageLabel(1)}
              </Box>
              <Box className={spacerClass} />
              <Box className={classes.header}>Total Adjustment</Box>
              <Box className={clsx([classes.header, classes.textRight])}>
                {getBalanceLabel(details.sourceAdjustment)}
              </Box>
              <Box
                className={clsx([
                  classes.header,
                  classes.green,
                  adjustmentColumnPositionClass,
                  classes.textRight,
                ])}
              >
                {getBalanceLabel(detailAdjustmentsTotalAdjustment)}
              </Box>
            </Box>
          ) : (
            <Typography variant='h6'>Not implemented yet</Typography>
          )}
        </Container>
      </Box>
      <Loader show={showLoader} />
    </>
  );
};
