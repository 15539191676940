import { BalancingViewMode, IBalancingItem } from './components/BalancingItem';
import { getAccounts, getTotalAdjustments } from '@services/api';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { BalancingItemWithTabs } from './components/BalancingItemWithTabs';
import { Box } from '@mui/material';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IProject } from '@models/interfaces/entities/IProject';
import { ITotalAdjustment } from '@models/interfaces/entities/ITotalAdjustment';
import { Loader } from '@components/Loader';
import useStyles from './styles';

interface IProps {
  project: IProject;
}

interface IBalancingNode {
  title: string;
  accountsType: number;
  balancingType: number;
  hideSource?: boolean;
  hideSectionAdjustments?: boolean;
  items?: IBalancingNode[];
}

const assets: IBalancingNode = {
  title: 'Assets',
  accountsType: -1,
  balancingType: 99,
  items: [
    {
      title: 'Loans',
      accountsType: 0,
      balancingType: 97,
    },
    {
      title: 'Allowance for Loan Loss',
      accountsType: 91,
      balancingType: 91,

      hideSource: true,
      hideSectionAdjustments: true,
    },
    {
      title: 'Investments',
      accountsType: 1,
      balancingType: 98,
    },
    {
      title: 'Non-Interest Assets',
      accountsType: 2,
      balancingType: 103,
      hideSource: true,
      hideSectionAdjustments: true,
    },
  ],
};

const liabilitiesAndNetWorth: IBalancingNode = {
  title: 'Liabilities and Net Worth',
  accountsType: -1,
  balancingType: 102,

  items: [
    {
      title: 'Deposits',
      accountsType: -1,
      balancingType: 106,
      items: [
        {
          title: 'Non-Maturity Deposits',
          accountsType: 5,
          balancingType: 100,
        },
        {
          title: 'Term Deposits',
          accountsType: 9,
          balancingType: 105,
        },
      ],
    },
    {
      title: 'Borrowings',
      accountsType: 4,
      balancingType: 101,
    },
    {
      title: 'Non-Int Liabilities',
      accountsType: 3,
      balancingType: 104,
      hideSource: true,
      hideSectionAdjustments: true,
    },
    {
      title: 'Net Worth',
      accountsType: 92,
      balancingType: 92,
      hideSource: true,
      hideSectionAdjustments: true,
    },
  ],
};

export const BalancingTab = ({ project }: IProps) => {
  const { classes } = useStyles();

  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [viewMode, setViewMode] = useState<BalancingViewMode>(BalancingViewMode.accounts);
  const [totalAdjustments, setTotalAdjustments] = useState<ITotalAdjustment[]>([]);

  const {
    request: getAccountsRequest,
    data: getAccountsData,
    loading: getAccountsLoading,
  } = useApi(getAccounts, null, { handleErrors: true });

  const {
    request: getTotalAdjustmentsRequest,
    data: getTotalAdjustmentsData,
    loading: getTotalAdjustmentsLoading,
  } = useApi(getTotalAdjustments, null, { handleErrors: true });

  useEffect(() => {
    if (project.links[Actions.getAccounts]) {
      getAccountsRequest(project.links[Actions.getAccounts].href);
    }
    if (project.links[Actions.getTotalAdjustments]) {
      getTotalAdjustmentsRequest(project.links[Actions.getTotalAdjustments].href);
    }
  }, [project.links[Actions.getAccounts]]);

  useUpdateEffect(() => {
    if (getAccountsData) {
      setAccounts(getAccountsData.items);
    }
  }, [getAccountsData]);

  useUpdateEffect(() => {
    if (getTotalAdjustmentsData) {
      setTotalAdjustments(getTotalAdjustmentsData);
    }
  }, [getTotalAdjustmentsData]);

  const onChangeViewMode = useCallback((mode: BalancingViewMode) => {
    setViewMode(mode);
  }, []);

  const calculateAccountsAmounts = useCallback(
    (type: number, totalAdjustments: ITotalAdjustment[]) => {
      const sourceBalance =
        totalAdjustments.find((adj) => adj.targetType === type && adj.operation === 'initial')
          ?.amount || 0;

      const adjustments = totalAdjustments
        .filter(
          (adj) =>
            adj.targetType === type &&
            ['manual', 'add', 'subtract', 'match', 'fees'].includes(adj.operation),
        )
        .reduce((sum, adj) => sum + adj.amount, 0);

      return { sourceBalance, adjustments };
    },
    [],
  );

  const calculateSectionAdjustmentsTotal = useCallback(
    (type: number, totalAdjustments: ITotalAdjustment[]) => {
      return totalAdjustments
        .filter((adj) => adj.targetType === type && adj.operation === 'balance')
        .reduce((sum, adj) => sum + adj.amount, 0);
    },
    [],
  );

  const calculateBalanceSheetTotal = useCallback(
    (type: number, totalAdjustments: ITotalAdjustment[]) => {
      const balanceSheetItems = totalAdjustments.filter(
        (adj) =>
          adj.targetType === type && ['addBalance', 'reverseBalance'].includes(adj.operation),
      );
      return {
        total: balanceSheetItems.reduce((sum, adj) => sum + adj.amount, 0),
        count: balanceSheetItems.length,
      };
    },
    [],
  );

  const convertToBalancingItem = useCallback(
    (node: IBalancingNode, totalAdjustments: ITotalAdjustment[]): IBalancingItem => {
      if (node.accountsType === -1 && node.items && node.items.length > 0) {
        const childItems = node.items.map((childNode: IBalancingNode) =>
          convertToBalancingItem(childNode, totalAdjustments),
        );

        const sourceBalance = childItems.reduce((sum, child) => sum + child.sourceBalance, 0);
        const adjustments = childItems.reduce((sum, child) => sum + child.adjustments, 0);
        const aggregatedTotalBalance = childItems.reduce(
          (sum, child) => sum + child.totalBalance,
          0,
        );
        const sectionAdjustments = calculateSectionAdjustmentsTotal(
          node.accountsType,
          totalAdjustments,
        );
        const { total: totalBalance, count } = calculateBalanceSheetTotal(
          node.balancingType,
          totalAdjustments,
        );

        return {
          title: node.title,
          accountsType: node.accountsType,
          balancingType: node.balancingType,
          sourceBalance,
          adjustments,
          sectionAdjustments,
          totalBalance: count ? totalBalance : aggregatedTotalBalance,
          hideSource: node.hideSource,
          hideSectionAdjustments: node.hideSectionAdjustments,
          items: childItems,
        };
      } else {
        const { sourceBalance, adjustments } = calculateAccountsAmounts(
          node.accountsType,
          totalAdjustments,
        );
        const sectionAdjustments = calculateSectionAdjustmentsTotal(
          node.accountsType,
          totalAdjustments,
        );
        const { total: totalBalance } = calculateBalanceSheetTotal(
          node.balancingType,
          totalAdjustments,
        );

        return {
          title: node.title,
          accountsType: node.accountsType,
          balancingType: node.balancingType,
          sourceBalance,
          adjustments,
          sectionAdjustments,
          totalBalance,
          hideSource: node.hideSource,
          hideSectionAdjustments: node.hideSectionAdjustments,
          items: node.items
            ? node.items.map((childNode: IBalancingNode) =>
                convertToBalancingItem(childNode, totalAdjustments),
              )
            : undefined,
        };
      }
    },
    [calculateAccountsAmounts, calculateBalanceSheetTotal],
  );

  const assetsItem = useMemo(
    () => convertToBalancingItem(assets, totalAdjustments),
    [assets, totalAdjustments, convertToBalancingItem],
  );
  const liabilitiesAndNetWorthItem = useMemo(
    () => convertToBalancingItem(liabilitiesAndNetWorth, totalAdjustments),
    [liabilitiesAndNetWorth, totalAdjustments, convertToBalancingItem],
  );

  const showLoader = useLoader(getAccountsLoading, getTotalAdjustmentsLoading);

  return (
    <Box className={classes.root}>
      <BalancingItemWithTabs
        project={project}
        item={assetsItem}
        accounts={accounts}
        mode={viewMode}
        onChangeMode={onChangeViewMode}
      />

      <BalancingItemWithTabs
        project={project}
        item={liabilitiesAndNetWorthItem}
        accounts={accounts}
        mode={viewMode}
        onChangeMode={onChangeViewMode}
      />
      <Loader show={showLoader} />
    </Box>
  );
};
