import BaseProvider from './BaseProvider';
import { IGetAdjustmentDefinitionResponse } from '@models/interfaces/responses/IGetAdjustmentDefinitionResponse';
import { IGetAdjustmentDefinitionsResponse } from '@models/interfaces/responses/IGetAdjustmentDefinitionsResponse';

export default class AdjustmentDefinitionsProvider extends BaseProvider {
  public getAdjustmentDefinitions = async (
    url: string,
    operation: string,
    categoryId?: string,
    type?: number,
  ) => {
    return this.get<IGetAdjustmentDefinitionsResponse>(url, {
      params: {
        operation,
        categoryId,
        type,
      },
    });
  };

  public getAdjustmentDefinition = async (url: string) => {
    return this.get<IGetAdjustmentDefinitionResponse>(url);
  };
}
