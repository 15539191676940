import BaseProvider from './BaseProvider';
import { IGetAdjustmentsResponse } from '@models/interfaces/responses/IGetAdjustmentsResponse';
import { IGetTotalAdjustmentsResponse } from '@models/interfaces/responses/IGetTotalAdjustmentsResponse';

export default class AdjustmentsProvider extends BaseProvider {
  public getAdjustments = async (
    url: string,
    operations: string[],
    accountIds?: string[],
    categoryId?: string,
    type?: number,
  ) => {
    return this.get<IGetAdjustmentsResponse>(url, {
      params: {
        operations,
        accountIds,
        categoryId,
        type,
      },
    });
  };

  public getTotalAdjustments = async (url: string) => {
    return this.get<IGetTotalAdjustmentsResponse>(url);
  };
}
