import { Box, FormControlLabel, IconButton, Switch, Tooltip } from '@mui/material';

import MinusIcon from '@assets/icons/dashboard/minus-square.svg';
import PlusIcon from '@assets/icons/dashboard/plus-square.svg';
import useStyles from './styles';

interface IProps {
  emptyAccountsVisibility: boolean;
  unadjustedAccountsVisibility: boolean;
  onChangeEmptyAccountsVisibility: (value: boolean) => void;
  onChangeUnadjustedAccountsVisibility: (value: boolean) => void;
  onPlusClicked: () => void;
  onMinusClicked: () => void;
}

export const AccountsTableFooter = ({
  emptyAccountsVisibility,
  unadjustedAccountsVisibility,
  onChangeEmptyAccountsVisibility,
  onChangeUnadjustedAccountsVisibility,
  onMinusClicked,
  onPlusClicked,
}: IProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.controlsContainer}>
        <FormControlLabel
          control={
            <Switch
              inputProps={{ role: 'switch' }}
              checked={emptyAccountsVisibility}
              onChange={(_, val) => onChangeEmptyAccountsVisibility(val)}
            />
          }
          label='Show accounts with zero balance'
        />

        <FormControlLabel
          control={
            <Switch
              inputProps={{ role: 'switch' }}
              checked={unadjustedAccountsVisibility}
              onChange={(_, val) => onChangeUnadjustedAccountsVisibility(val)}
            />
          }
          label='Show accounts without adjustments'
        />
      </Box>
      <Box className={classes.controlsContainer}>
        <Tooltip title='Click here to decrease the table height'>
          <IconButton onClick={onMinusClicked}>
            <img src={MinusIcon} alt='-' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Click here to increase the table height'>
          <IconButton onClick={onPlusClicked}>
            <img src={PlusIcon} alt='+' />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};
