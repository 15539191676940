import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { AccountsTable } from '../AccountsTable';
import { BalanceSheetTable } from '../BalanceSheetTable';
import ChevronDownIcon from '@assets/icons/dashboard/chevron-down-gray.svg';
import ChevronUpIcon from '@assets/icons/dashboard/chevron-up-gray.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IProject } from '@models/interfaces/entities/IProject';
import clsx from 'clsx';
import useStyles from './styles';

export enum BalancingViewMode {
  accounts = 'accounts',
  balanceSheet = 'balanceSheet',
}

export interface IBalancingItem {
  title: string;
  accountsType: number;
  balancingType: number;
  sourceBalance: number;
  adjustments: number;
  sectionAdjustments: number;
  totalBalance: number;
  hideSource?: boolean;
  hideSectionAdjustments?: boolean;
  items?: IBalancingItem[];
}

interface IProps {
  project: IProject;
  accounts: IAccount[];
  main?: boolean;
  item: IBalancingItem;
  mode: BalancingViewMode;
  onChangeMode: (mode: BalancingViewMode) => void;
}

export const BalancingItem = ({ project, accounts, main, item, mode, onChangeMode }: IProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);

  const onToggleExpanded = () => {
    setExpanded((val) => !val);
  };

  const onBalanceClick = (mode: BalancingViewMode) => {
    setExpanded(true);
    onChangeMode(mode);
  };

  const getBalanceLabel = useCallback((value: number) => {
    const formatted = `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Math.abs(value))}`;
    return value < 0 ? `(${formatted})` : formatted;
  }, []);

  const balanceRecords = [
    {
      label: 'Source Balance',
      amount: item.hideSource ? 'N/A' : getBalanceLabel(item.sourceBalance),
      class: 'blue',
      mode: BalancingViewMode.accounts,
    },
    {
      label: 'Line Item Adjustments',
      amount: getBalanceLabel(item.adjustments),
      class: 'blue',
      mode: BalancingViewMode.accounts,
    },
    {
      label: 'Section Adjustments',
      amount: item.hideSectionAdjustments ? 'N/A' : getBalanceLabel(item.sectionAdjustments),
      class: 'blue',
      mode: BalancingViewMode.accounts,
    },
    {
      label: 'Balance Sheet Total',
      amount: getBalanceLabel(item.totalBalance),
      class: 'green',
      mode: BalancingViewMode.balanceSheet,
    },
  ];

  return (
    <Box className={clsx([classes.root, (main || !expanded) && 'shadowed'])}>
      <Box className={classes.header}>
        <Typography variant='h6'>{item.title}</Typography>
        <Box className={classes.balancesContainer}>
          {balanceRecords.map((balance) => (
            <Box className={classes.balanceRecord} key={balance.label}>
              <Typography variant='subtitle2'>{balance.label}</Typography>
              <Box
                className={clsx([
                  classes.balanceCount,
                  balance.class,
                  balance.mode === mode && 'active',
                ])}
                onClick={() => onBalanceClick(balance.mode)}
              >
                <Typography variant='subtitle2'>{balance.amount}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <IconButton onClick={onToggleExpanded} className={classes.expandIcon}>
          {expanded ? (
            <img alt='arrow up' src={ChevronUpIcon} />
          ) : (
            <img alt='arrow down' src={ChevronDownIcon} />
          )}
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        {item.items?.length ? (
          <Box className={classes.itemsListContent}>
            {mode === BalancingViewMode.balanceSheet && (
              <BalanceSheetTable type={item.balancingType} project={project} />
            )}
            {item.items.map((item) => (
              <BalancingItem
                key={item.title}
                project={project}
                accounts={accounts}
                item={item}
                mode={mode}
                onChangeMode={onChangeMode}
              />
            ))}
          </Box>
        ) : (
          <Box className={classes.itemContent}>
            {mode === BalancingViewMode.accounts ? (
              <AccountsTable
                type={item.accountsType}
                project={project}
                accounts={accounts}
                category={item.title}
              />
            ) : (
              <BalanceSheetTable type={item.balancingType} project={project} />
            )}
          </Box>
        )}
      </Collapse>
    </Box>
  );
};
