import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useApi, useLoader } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { Box } from '@mui/material';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IProject } from '@models/interfaces/entities/IProject';
import { Loader } from '@components/Loader';
import { StandardTableFooter } from '../StandardTableFooter';
import { getAdjustments } from '@services/api';
import useStyles from './styles';

enum AdjustmentOperation {
  AddBalance = 'addBalance',
  ReverseBalance = 'reverseBalance',
}

interface IBalanceSheetLineItem {
  id: string;
  sourceSubAccountId: string;
  sourceSubAccountDescription: string;
  balance: number;
  operation: string;
  contribution: number;
}

interface IProps {
  project: IProject;
  type: number;
}

export const BalanceSheetTable = ({ project, type }: IProps) => {
  const { classes } = useStyles();

  const [addAdjustments, setAddAdjustments] = useState<IAdjustment[]>([]);
  const [reverseAdjustments, setReverseAdjustments] = useState<IAdjustment[]>([]);
  const [height, setHeight] = useState(250);

  const { request: getAdjustmentsRequest, loading: getAdjustmentsLoading } = useApi(
    getAdjustments,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setAddAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.AddBalance),
          );
          setReverseAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.ReverseBalance),
          );
        }
      },
    },
  );

  const getBalanceLabel = useCallback((value: number) => {
    const formatted = `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Math.abs(value))}`;
    return value < 0 ? `(${formatted})` : formatted;
  }, []);

  const onDecreaseTableHeight = () => {
    setHeight((prev) => Math.max(prev/2, 250));
  };

  const onIncreaseTableHeight = () => {
    setHeight((prev) => Math.min(prev*2, 1000));
  };

  const columns = useMemo(
    () =>
      [
        {
          field: 'sourceSubAccountId',
          headerName: 'BS Line Item',
          type: 'string',
          flex: 2,
        },
        {
          field: 'sourceSubAccountDescription',
          headerName: 'Description',
          type: 'string',
          flex: 2,
        },
        {
          field: 'balance',
          headerName: 'Balance',
          type: 'number',
          flex: 2,
          renderCell: (params) =>
            params.value !== undefined ? getBalanceLabel(params.value) : undefined,
        },
        {
          field: 'operation',
          headerName: 'Operation',
          type: 'string',
          flex: 2,
        },
        {
          field: 'contribution',
          headerName: 'Contribution',
          type: 'number',
          flex: 2,
          renderCell: (params) => getBalanceLabel(params.value || 0),
        },
      ] as GridColDef<IBalanceSheetLineItem>[],
    [],
  );

  useEffect(() => {
    if (project.links[Actions.getAdjustments]) {
      getAdjustmentsRequest(
        project.links[Actions.getAdjustments].href,
        [AdjustmentOperation.AddBalance, AdjustmentOperation.ReverseBalance],
        undefined,
        undefined,
        type,
      );
    }
  }, [project.links[Actions.getAdjustments]?.href]);

  const balanceSheetLineItems = useMemo<IBalanceSheetLineItem[]>(() => {
    return [...addAdjustments, ...reverseAdjustments].map((x) => ({
      id: x.id,
      sourceSubAccountId: x.sourceSubAccountId || 'Unknown',
      sourceSubAccountDescription: x.sourceSubAccountDescription || '',
      balance: x.sourceAdjustment,
      operation: x.operation === AdjustmentOperation.AddBalance ? 'Add' : 'Reverse',
      contribution: x.amount,
    }));
  }, [addAdjustments, reverseAdjustments]);

  const showLoader = useLoader(getAdjustmentsLoading);

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          style={{ height: balanceSheetLineItems.length ? height : 'auto' }}
          rows={balanceSheetLineItems}
          density='compact'
          columns={columns}
          className={classes.table}
          initialState={{
            sorting: {
              sortModel: [{ field: 'accountName', sort: 'asc' }],
            },
            aggregation: {
              model: {
                contribution: 'sum',
              },
            },
          }}
          slots={{
            footer: () => (
              <StandardTableFooter
                onMinusClicked={onDecreaseTableHeight}
                onPlusClicked={onIncreaseTableHeight}
              />
            ),
          }}
        />
      </Box>
      <Loader show={showLoader} />
    </>
  );
};
