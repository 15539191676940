import { Box, IconButton, Tooltip } from '@mui/material';

import MinusIcon from '@assets/icons/dashboard/minus-square.svg';
import PlusIcon from '@assets/icons/dashboard/plus-square.svg';
import useStyles from './styles';

interface IProps {
  onPlusClicked: () => void;
  onMinusClicked: () => void;
}

export const StandardTableFooter = ({ onMinusClicked, onPlusClicked }: IProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.controlsContainer}>
        <Tooltip title='Click here to decrease the table height'>
          <IconButton onClick={onMinusClicked}>
            <img src={MinusIcon} alt='-' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Click here to increase the table height'>
          <IconButton onClick={onPlusClicked}>
            <img src={PlusIcon} alt='+' />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};
