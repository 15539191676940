import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    position: 'relative',
  },
  expandButton: {
    width: 100,
    backgroundColor: t.palette.custom.info[50],
    paddingTop: 4,
    paddingBottom: 4,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover, &:active': {
      backgroundColor: t.palette.custom.info[70],
    },
  },
  table: {
    backgroundColor: t.palette.custom.white[100],

    '& .MuiDataGrid-aggregationColumnHeaderLabel': {
      display: 'none',
    },

    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },

    '& .MuiDataGrid-pinnedRows': {
      fontWeight: 600,
      '& div[data-field="accountName"]': {
        '&::after': {
          content: '"Filtered Total"',
          display: 'block',
        },
      },
    },
  },
}));

export default useStyles;
