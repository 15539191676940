import { BalancingItem, BalancingViewMode, IBalancingItem } from '../BalancingItem';
import { Box, Typography } from '@mui/material';

import { IAccount } from '@models/interfaces/entities/IAccount';
import { IProject } from '@models/interfaces/entities/IProject';
import clsx from 'clsx';
import { useCallback } from 'react';
import useStyles from './styles';

interface IProps {
  project: IProject;
  accounts: IAccount[];
  item: IBalancingItem;
  mode: BalancingViewMode;
  onChangeMode: (mode: BalancingViewMode) => void;
}

export const BalancingItemWithTabs = ({ project, accounts, item, mode, onChangeMode }: IProps) => {
  const { classes } = useStyles();

  const getBalanceLabel = useCallback((value: number) => {
    const formatted = `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Math.abs(value))}`;
    return value < 0 ? `(${formatted})` : formatted;
  }, []);

  return (
    <Box className={clsx([classes.root, mode === BalancingViewMode.accounts ? 'rp-0' : 'lp-0'])}>
      <Box
        className={clsx([
          classes.tab,
          'left',
          'blue',
          mode === BalancingViewMode.accounts && 'active',
        ])}
        onClick={() => onChangeMode(BalancingViewMode.accounts)}
      >
        <Typography variant='subtitle1'>
          {getBalanceLabel(item.sourceBalance + item.adjustments + item.sectionAdjustments)}
        </Typography>
      </Box>
      <Box
        className={clsx([
          classes.tab,
          'right',
          'green',
          mode === BalancingViewMode.balanceSheet && 'active',
        ])}
        onClick={() => onChangeMode(BalancingViewMode.balanceSheet)}
      >
        <Typography variant='subtitle1'>{getBalanceLabel(item.totalBalance)}</Typography>
      </Box>
      <BalancingItem
        project={project}
        item={item}
        main
        accounts={accounts}
        mode={mode}
        onChangeMode={onChangeMode}
      />
    </Box>
  );
};
